import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { FaAward, FaTrophy, FaGlobe } from "react-icons/fa";

function AboutCard() {
  const { t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t("about.card.text")}
            <br />
            <br />

            <FaTrophy /> {t("about.card.recognitionsAndAwardsText")}
            <ul>
              <li className="about-activity">
                <FaGlobe /> {t("about.card.recognitionsAndAwards.nasaSpaceApps2024.title")}
                <br />
                <i>
                  {
                    t("about.card.recognitionsAndAwards.granted")
                    + " " + t("about.card.recognitionsAndAwards.nasaSpaceApps2024.granted")
                    + " (" + t("about.card.recognitionsAndAwards.nasaSpaceApps2024.date") + ")"
                  }
                </i>
              </li>
              <li className="about-activity">
                <FaAward /> {t("about.card.recognitionsAndAwards.campusDay.title")}
                <br />
                <i>
                  {
                    t("about.card.recognitionsAndAwards.granted")
                    + " " + t("about.card.recognitionsAndAwards.campusDay.granted")
                    + " (" + t("about.card.recognitionsAndAwards.campusDay.date") + ")"
                  }
                </i>
              </li>
              <li className="about-activity">
                <FaAward /> {t("about.card.recognitionsAndAwards.merit.title")}
                <br />
                <i>
                  {
                    t("about.card.recognitionsAndAwards.granted")
                    + " " + t("about.card.recognitionsAndAwards.merit.granted")
                    + " (" + t("about.card.recognitionsAndAwards.merit.date") + ")"
                  }
                </i>
              </li>
            </ul>

            {/* <br />
            {t("about.card.activitiesText")}
            <ul>
              <li className="about-activity">
                <ImPointRight /> {t("about.card.activities.soccer")}
              </li>
              <li className="about-activity">
                <ImPointRight /> {t("about.card.activities.watchMoviesandSeries")}
              </li>
              <li className="about-activity">
                <ImPointRight /> {t("about.card.activities.travel")}
              </li>
              <li className="about-activity">
                <ImPointRight /> {t("about.card.activities.run")}
              </li>
            </ul> */}
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "{t("about.card.quote.text")}"{" "}
          </p>
          <footer className="blockquote-footer">{t("about.card.quote.author")}</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
