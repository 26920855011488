import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import pt_brTranslation from './locales/pt_br.json';
import esTranslation from './locales/es.json';

const resources = {
    en: {
        translation: enTranslation
    },
    pt_br: {
        translation: pt_brTranslation
    },
    es: {
        translation: esTranslation
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        debug: true,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
    });


export default i18n;