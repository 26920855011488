import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiNodejs,
  DiJava,
  DiMongodb,
  DiGo,
  DiAws,
} from "react-icons/di";
import {
    SiRedis,
    SiPostgresql,
    SiPhp,
    SiSpring,
    SiMysql,
    SiSymfony,
    SiNestjs,
    SiDocker,
    SiNginx,
    SiDigitalocean
} from "react-icons/si";

export function Languages() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "3.125em" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPhp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGo />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
    </Row>
  );
}

export function Databases() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "3.125em" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>
    </Row>
  );
}

export function Frameworks() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "3.125em" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiSymfony />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNestjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpring />
      </Col>
    </Row>
  );
}

export function DevOps() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "3.125em" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDigitalocean />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNginx />
      </Col>
    </Row>
  );
}
