import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { useTranslation } from "react-i18next";

function Home2() {
  const today = new Date();
  const yearsOld = today.getFullYear() - 2000;
  const { t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {
                <p>{t('home.about.title')}</p>
              }
            </h1>
            <p className="home-about-body">
              {t("home.about.text", {
                yearsOld 
              })}
              <i>
                <b className="purple">
                  {t("home.about.interpersonalSkills")}
                </b>
              </i>
              {/* 
                Discover after how to escape html tags with componentes
              */}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src="https://avatars.githubusercontent.com/u/65042811?v=4" className="img-fluid circular-img" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t("home.about.findMe")}</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/potatowski"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:vitorrocha3105@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiGmail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/vitorrch/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
