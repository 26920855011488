import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Github() {
  const { t } = useTranslation();
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "0.625em" }}>
      <h1 className="project-heading" style={{ paddingBottom: "1.1em" }}>
        {t("about.daysCode")}
      </h1>
      <GitHubCalendar
        username="potatowski"
        blockSize={13}
        blockMargin={5}
        color="#c084f5"
        fontSize={16}
      />
    </Row>
  );
}

export default Github;
