import React from "react";
import Github from "./Github";
import Toolstack from "./Toolstack";
import { Languages, Databases, Frameworks, DevOps } from "./TechStacks";
import { useTranslation } from "react-i18next";


function Professional() {
  const { t } = useTranslation();
  return (
    <>
        <h1 className="project-heading">
          {t("about.professionalStacks")}
        </h1>

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.langs")} </strong>
        </h1>
        <Languages />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.databases")} </strong>
        </h1>
        <Databases />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.frameworks")} </strong>
        </h1>
        <Frameworks />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.devOps")} </strong>
        </h1>
        <DevOps />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.tools")} </strong>
        </h1>
        <Toolstack />

        <Github />
    </>
  );
}

export default Professional;
