import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import AboutCard from "./AboutCard";
import laptopImg from "../../assets/about.png";
import Toolstack from "./Toolstack";
import { Languages, Databases, Frameworks, DevOps } from "./TechStacks";
import { useTranslation } from "react-i18next";


function About() {
  const { t } = useTranslation();
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "0.625em" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "1.875em",
              paddingBottom: "3.125em",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "1.1em" }}>
              {t("about.knowWho")} <strong className="purple">{t("about.im")}</strong>
            </h1>
            <AboutCard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "7.5em", paddingBottom: "3.125em" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>

        <h1 className="project-heading">
          {t("about.professionalStacks")}
        </h1>

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.langs")} </strong>
        </h1>
        <Languages />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.databases")} </strong>
        </h1>
        <Databases />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.frameworks")} </strong>
        </h1>
        <Frameworks />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.devOps")} </strong>
        </h1>
        <DevOps />

        <h1 className="project-heading">
          <strong className="purple"> {t("about.stacks.tools")} </strong>
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
