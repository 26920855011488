import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import AboutCard from "./AboutCard";
import Professional from "./Professional";


function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <AboutCard />
        <Professional />
      </Container>
    </Container>
  );
}

export default About;
