import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import enPdf from "../../assets/curriculum/en.pdf";
import ptbrPdf from "../../assets/curriculum/pt_br.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Resume() {
  const { t, i18n } = useTranslation();

  const pdfFiles = {
    en: enPdf,
    pt_br: ptbrPdf,
  }

  const currentLanguage = i18n.language;
  const pdfFile = pdfFiles[currentLanguage] || pdfFiles.en;

  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdfFile}
            target="_blank"
            style={{ maxWidth: "15.625em" }}
          >
            <AiOutlineDownload />
            {t("resume.download")}
          </Button>
        </Row>

        <Row className="resume">
          <Document file={pdfFile} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
          </Document>
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdfFile}
            target="_blank"
            style={{ maxWidth: "15.625em" }}
          >
            <AiOutlineDownload />
            {t("resume.download")}
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default Resume;