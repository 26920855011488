import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

function LanguageSelector({ updateExpanded }) {
    const { t, i18n } = useTranslation();

    const handleChangeLanguage = (value) => {
        i18n.changeLanguage(value);
        updateExpanded(false);
    };

    return (
        <Nav.Item>
            <NavDropdown
                title={
                    <>
                        <FaGlobe style={{ marginRight: "0.312em" }} />
                        {t("lang")}
                    </>
                }
                id="language-select"
            >
                <NavDropdown.Item onClick={() => handleChangeLanguage("en")}>EN-US</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleChangeLanguage("pt_br")}>PT-BR</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleChangeLanguage("es")}>ES-ES</NavDropdown.Item>
            </NavDropdown>
        </Nav.Item>
    );
}

export default LanguageSelector;