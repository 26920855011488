import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import socialNetworkAPI from "../../assets/projects/social-network-api.webp";
import serviceMonitor from "../../assets/projects/service-monitor.webp";
import brazilCode from "../../assets/projects/brazilcode.webp";
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t("projects.title")}
        </h1>
        <p style={{ color: "white" }}>
          {t("projects.subtitle")}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "0.625em" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={socialNetworkAPI}
              isBlog={false}
              title={t("projects.socialNetworkAPI.title")}
              description={t("projects.socialNetworkAPI.description")}
              ghLink="https://github.com/potatowski/social-network-api"
              // demoLink=""
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={serviceMonitor}
              isBlog={false}
              title={t("projects.serviceMonitor.title")}
              description={t("projects.serviceMonitor.description")}
              ghLink="https://github.com/potatowski/service-monitor"
              // demoLink=""
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={brazilCode}
              isBlog={false}
              title={t("projects.brazilCode.title")}
              description={t("projects.brazilCode.description")}
              ghLink="https://github.com/potatowski/brazilcode"
              packageLink="https://pkg.go.dev/github.com/potatowski/brazilcode#section-readme"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
